import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "ODFmYjJhMTE1YzRhNTcxZWU4YWM3YjkyOWI0MzA2OGE=",
	"Tenant-Access-Key": "-UbxDmuETVEBqA",
};

export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
