import React from "react";
// import logo from '../../assets/images/backgrounds/LOGO_PAGE.jpg';
import "./style.scss";
import logo from "../../assets/images/zap_money_logo.png";

const SplasScreen = () => {
	return (
		<>
			<div
				className="image__container"
				style={{ display: "flex", flexDirection: "column" }}>
				<img src={logo} alt="logo" className="logo__main" />
				<span style={{ color: "white", marginBottom: "1rem" }}>
					{`powered by Monexo (NBFC-P2P)`}
				</span>
			</div>
		</>
	);
};

export default SplasScreen;
